import React,{useRef, useEffect} from 'react';
import Row from './Row';
import Column from './Column';
import { Tween } from 'gsap/gsap-core';
import {TweenMax,Power2,TimelineMax} from "gsap";
import hamburger from '../../assets/menu.png';
import background from '../../assets/party.png';
import FadeIn from 'react-fade-in';
import responsive from "../../assets/responsive.svg";
import dev from "../../assets/dev.svg";
import html from "../../assets/html.svg";
import TextCarousel from "./TextCarousel";
import './divider.css';
import Perspective from './Perspective';
import './LandingPage.css'
import Navbar from '../../navbar/Navbar'


function LandingPage() {
  
   const width = window.innerWidth;
   const height = window.innerHeight;

  const heroRef = useRef(null);
  const sliderRef = useRef(null);
  const logoRef = useRef(null);
  const headlineRef = useRef(null);
  const bannerRef= useRef(null);
  const footerRef= useRef(null);
  const dividerRef= useRef(null);


  
function Animation(){
  if(width >= height){
  TweenMax.fromTo(heroRef.current,1,{height: "0%",ease: Power2.easeInOut},{height: '80%',delay:"1"})
  TweenMax.fromTo(heroRef.current,1.2,{width:"100%"},{width:"80%",delay:"2"},Power2.easeInOut);
  TweenMax.fromTo(sliderRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
  TweenMax.fromTo(logoRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
  TweenMax.fromTo(bannerRef.current, 1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
  TweenMax.fromTo(footerRef.current, 1.2,{opacity: "0%"},{opacity: "70%", ease:Power2.easeInOut,delay:"2.2"});
  TweenMax.fromTo(dividerRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
  }else{
    TweenMax.fromTo(heroRef.current,1,{height: "0%",ease: Power2.easeInOut},{height: '50%',delay:"1"})
    TweenMax.fromTo(heroRef.current,1.2,{width:"100%"},{width:"80%",delay:"2"},Power2.easeInOut);
    TweenMax.fromTo(sliderRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
    TweenMax.fromTo(logoRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
    TweenMax.fromTo(bannerRef.current, 1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
    TweenMax.fromTo(footerRef.current, 1.2,{opacity: "0%"},{opacity: "70%", ease:Power2.easeInOut,delay:"2.2"});
    TweenMax.fromTo(dividerRef.current,1.2,{x:"-100%"},{x:"0%",ease: Power2.easeInOut,delay:"1.2"});
  }
}

  useEffect(()=>{
   Animation();
  },[])

  return (
    <div>
         <div className="stick" ref={logoRef}>
        <Navbar></Navbar>
        </div>
    <div className="App">
      <header className="App-header">
     
          <section>
            <div ref={heroRef} className="hero">
              <img src={background}/>
              <TextCarousel ref={headlineRef}> </TextCarousel>
            </div>
          </section>
      </header>
      <div ref={sliderRef}className="slider"></div>
     
 
     
     
    </div>
    <div ref={dividerRef}className="rounded">Welcome </div>
    <div ref={bannerRef} className="banner">
      <div className=" dir">
        <div className="column pers">
        <Perspective></Perspective>
        
      
        </div>
        <div className="column">
          <div className="banner-text contain-text">
          An Agency ready to take on the world.
          </div>
        </div>
        </div>
      </div>
    
    <div className="row">
      <div className="column"></div>
      <div className="column"> </div>
      <div className="column"></div>
    
      </div>

    </div>
  );
}

export default LandingPage;
