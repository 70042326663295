import React, {useState,useEffect,useRef } from 'react'
import {Button} from './Button'
import {MenuItems} from "./MenuItems"
import "./Navbar.css"
import hamburger from "../assets/menu1.svg";
import exit from '../assets/error.svg'
import { Tween } from 'gsap/gsap-core';
import {TweenMax,Power2,TimelineMax, Linear} from "gsap";
import '../Components/Contact/Contact.css'
import { useHistory, Link } from 'react-router-dom';


var clicked2 = false; 
var contactRef; 
var dimRef;
const width = window.innerWidth;
const height = window.innerHeight;


  function Load(ref){
    TweenMax.set(ref.current, {opacity:0, zIndex:2, display:'none'});
     }
     function Exit(ref){
      if(width >= 1000 ){
      TweenMax.fromTo(ref.current,0.6,{height: "60vw",opacity:1,ease: Power2.easeInOut},{height: '0%',opacity:0});
     TweenMax.to(ref.current,1,{display:"none"}); 
    }
    else{
      TweenMax.fromTo(ref.current,0.6,{height: "120vh",opacity:1,ease: Power2.easeInOut},{height: '0%',opacity:0});
      TweenMax.to(ref.current,1,{display:"none"}); 
    }
  }
  function contactClickedAnimation(ref){
    if(width >= 1000){
        TweenMax.to(ref.current, 1, {opacity:1, zIndex:10, display:'initial'});
    TweenMax.fromTo(ref.current,1,{height: "0%",ease: Power2.easeInOut},{height: '60vw',});
  }  else{
    TweenMax.to(ref.current, 1, {opacity:1, zIndex:10, display:'initial'});
    TweenMax.fromTo(ref.current,1,{height: "0%",ease: Power2.easeInOut},{height: '120vh',});
  }
    
  }
  
function Navbar(){
    contactRef = useRef(null);
        
        useEffect(()=>{
         Load(dimRef);
        },[])
    const [navbar,setNavbar] = useState(false);
    const [clicked,setClicked]= useState(false);
    const [contact,setContact]= useState(false);
    const changeBackground = () => {
        if(window.scrollY >= 80){
        setNavbar(true)
        }else{
            setNavbar(false);
        }
        }
    const Active = () =>{
        if(!clicked){
        setClicked(true);
        }
        else{
            setClicked(false);
        }
    }
    const ContactClicked = () =>{
        if(!clicked2){
            contactClickedAnimation(dimRef);
            
            clicked2 = true;
            }
            else{
                Exit(dimRef);
                clicked2 = false;
            }
    }
        window.addEventListener('scroll',changeBackground);
        return(
            <div>
               
            <nav className={navbar ? "NavbarItems change ": 'NavbarItems '}>
                <h1 className="navbar-logo brandName-size">
                  <div  className="logo-name"><Link to='/home'>LIT.</Link></div></h1>
                <div onClick={Active} className="menu-icon" >
                <div id="NavIcon">
	              <div className={clicked ? 'circle icon close': 'circle icon'}>
		            <span class="line top"></span>
		            <span class="line middle"></span>
		            <span class="line bottom"></span>
	              </div>
                </div>
                </div>
               
                <ul  className={clicked ? 'nav-menu active': 'nav-menu'}>
                           
                            <div className="borderXwidth">
                            <li className='menuItems-space '>
                               
                                <a className="nav-links bracketsNav" > <Link to="/portfolio">Portfolio</Link>
                                </a>
                                
                            </li>
                            </div>
                            <div className="borderXwidth">
                            <li className='menuItems-space '>
                                <a className="nav-links bracketsNav" ><Link to="/pricing">Pricing</Link>
                                </a>
                            </li>
                            </div>
                          
                            <div className="borderXwidth">
                            <li className='menuItems-space '>
                                <a className="nav-links bracketsNav"><Link to="/team">Team</Link>
                                </a>
                            </li>
                            </div>
                          
                            <div className="borderXwidth">
                            <li className='menuItems-space '>
                                <a onClick={ContactClicked}className="nav-links bracketsNav" >Contact
                                </a>
                            </li>
                            </div>
                          
                   
                    
                </ul>
            </nav>
            <div >
            <Contact   ></Contact>
            </div>
            </div>

        )
    }


export default Navbar;

function Contact(){
    dimRef = useRef()
    const ContactClicked = () =>{
        console.log(clicked2)
                
                Exit(dimRef);
                clicked2 = false;
                console.log(contactRef)
    
    }
      return(
          
              <div ref={dimRef}   class="container">
    <div class="kard">
      <div class="front face">
        <h1 className="front-title">LIT.</h1>
        <h1 className="clickme">tap me!</h1>
      </div>
      <div class="back face">
      <div onClick={ContactClicked}>
      <img  width="50%" height="50%" src={exit} className="exit "></img>
      </div>
      <h2 clasName="back-title">Think LIT.</h2>
        <ul className="Item-list">
        <li className="item">
            <span className="info-title">Get In Touch!</span>
          </li>
          <li className="item">
          <div id="examples">
      <div class="example">
        <a class="hover hover-1">647-702-7168</a>
      </div>
      </div>
          </li>
          <li className="item">
          <div id="examples">
      <div class="example">
            <a className=" hover hover-1" href="mailto:davidjnunezf@gmail.com">
              ThinkLit2k21@gmail.com
            </a>
            </div>
            </div>
      
          </li>
          <li className="item">
            <span className="info-title"></span>
            <a href="https://www.instagram.com/thinklit/" className="icon">
            <i class="fa fa-instagram"></i>
            </a>
            <a className="icon"href="https://www.facebook.com/ThinkLit2021">
            <i class="fa fa-facebook"></i>
            </a>
            <a className="icon" href="www.thinklit.ca">
            <i class="fa fa-twitter"></i>
            </a>
          </li>
        </ul>
        <a>
          <div class="icon-share"></div>
        </a>
      </div>
    </div>
  
          </div>
  
      );
    
  }