import react,  { useRef, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import PricingTable from './PricingTable'
import PricingTable2 from './PricingTable2'
import { TweenMax, Power2 } from "gsap";
import arrowLeft from '../../assets/arrowLeft.svg'
import arrowRight from '../../assets/arrowRight.svg'

var width = window.innerWidth;

function Pricing(){
  const table1Ref = useRef(null);
  const table2Ref = useRef(null);
  const [clicked, setClicked] = useState(true);
    function upTrans(){
        setClicked(!clicked);
        if(width >900){
        if(clicked){
          TweenMax.fromTo(table1Ref.current, 2, {y:"0%", ease:Power2.easeInOut, opacity:"1", marginBottom:"0vw"}, {y:"-115%", opacity:"0", marginBottom:"81.96px"})
          TweenMax.fromTo(table2Ref.current, 2, {y:"0%", ease:Power2.easeInOut, opacity:"0"}, {y:"-115%",opacity:"1"})
        }
        else{
          TweenMax.fromTo(table1Ref.current, 2, {y:"-115%", ease:Power2.easeInOut, opacity:"0", marginBottom:'81.96px'}, {y:"0%", opacity:"1", marginBottom:'0vw'})
          TweenMax.fromTo(table2Ref.current, 2, {y:"-115%", ease:Power2.easeInOut,opacity:"1"}, {y:"0%",opacity:"0"})
        }
      }
      else{
        if(clicked){
          TweenMax.fromTo(table1Ref.current, 2, {y:"0%", ease:Power2.easeInOut, opacity:"1", marginBottom:"0vw"}, {y:"-100%", opacity:"0", marginBottom:"0.96px"})
          TweenMax.fromTo(table2Ref.current, 2, {y:"0%", ease:Power2.easeInOut, opacity:"0"}, {y:"-100%",opacity:"1"})
        }
        else{
          TweenMax.fromTo(table1Ref.current, 2, {y:"-100%", ease:Power2.easeInOut, opacity:"0", marginBottom:'0.96px'}, {y:"0%", opacity:"1", marginBottom:'0vw'})
          TweenMax.fromTo(table2Ref.current, 2, {y:"-100%", ease:Power2.easeInOut,opacity:"1"}, {y:"0%",opacity:"0"})
        }
      }
    };

    return(
        
          <div  className="pric">
          <div className="stick">
          <Navbar/>
          </div>
          <div class="web-dev">
          
          
          <div className="pric-tables">
            <div className="pric-table">
              <div ref={table1Ref}>
            <div className="table-sec">  
              <div className="title">
          
              
              <h2 onClick={upTrans}>   <img  className="fade" src={arrowLeft} /><span className="imgpad2" >Web</span> <a className="imgpad">Design </a> <img  src={arrowRight} />  </h2> 
       
              </div>
              <PricingTable/>
              </div>
              </div>
              <div style={{opacity:"0"}} ref={table2Ref}>
                <div className="title">
                <h2 onClick={upTrans}>   <img   src={arrowLeft} /><span className="imgpad2">Digital</span> <a className="imgpad">Marketing</a> <img  className="fade" src={arrowRight} />  </h2> 
            
                </div>
              <PricingTable2/> 
              </div>
            </div>
          </div>
          </div>
        </div>
        
    );
}

export default Pricing;


    /*  <Container
      fluid
      style={{
        height: "100%",
        width: "100%",
        
        textAlign: 'center',
       
        
      }}
    >
      <FadeIn delay="140" >
      <Row style={{ color: "white", height: "100%" ,paddingTop:"8%"}}>
          <Col xs={12} md={6} style={{paddingBottom:"20%"}}>
          <div class="hover">
            <img
              src={html}
              width="50%"
              height="50%"
            />
            </div>
         </Col>
         <Col style={{  fontSize:"5vw"
        }} >Your favorite web development firm.</Col>
      
      
      </Row>
      </FadeIn>
    </Container>

    <Container
      fluid
      style={{
        height: "50%",
        width: "50%",
        
        textAlign: 'center',
        
      }}
    >
      <FadeIn delay="140" >
      <Row style={{ color: "white", height: "100%" ,paddingTop:"3%"}}>
      <Col style={{  fontSize:"5vw",paddingBottom:"10%"
        }} >High quality web sites
        <br></br>
         </Col>
          <Col xs={12} md={6} style={{paddingBottom:"20%"}}>
          <div class="hover">
            <img
              src={dev}
              width="50%"
              height="50%"
            />
            </div>
         </Col>

      
      
      </Row>
      </FadeIn>
    </Container>
    <Container
      fluid
      style={{
        height: "100%",
        width: "100%",
        
        textAlign: 'center',
       
        
      }}
    >
      <FadeIn delay="140" >
      <Row style={{ color: "white", height: "100%" ,paddingTop:"8%"}}>
          <Col xs={12} md={6} style={{paddingBottom:"20%"}}>
          <div class="hover">
            <img
              src={responsive}
              width="100%"
              height="100%"
            />
            </div>
         </Col>
         <Col style={{  fontSize:"5vw"
        }} >Elegant and Responsive Designs</Col>
      
      
      </Row>
      </FadeIn>
    </Container>
    */