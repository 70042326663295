import './TeamCard.css'
import {React, Component} from 'react'
import brian from '../../assets/brian.JPG';
import david from '../../assets/david.jpg';
class TeamCard extends Component{

    render(){
        return(
            <div class="teamCard">
            <div class="canvas-wrapper">
            <a  class="canvas">
               <div class="canvas_border">
                  <svg>
                     <defs>
                        <linearGradient id="grad-orange" x1="0%" y1="0%" x2="100%" y2="0%">
                           <stop offset="0%" stopColor="#fd8944" stopOpacity="1" ></stop>
                           <stop offset="100%" stopColor="#994b17" stopOpacity="1"></stop>
                        </linearGradient>
                        <linearGradient id="grad-red" x1="0%" y1="0%" x2="100%" y2="0%">
                           <stop offset="0%" stop-color="#e92c3e"></stop>
                           <stop offset="100%" stop-color="#e92c3e"></stop>
                        </linearGradient>
                     </defs>
                     <rect id="rect-grad" class="rect-gradient" fill="none" stroke="url(#grad-orange)" stroke-linecap="square" stroke-width="12" stroke-miterlimit="30" width="100%" height="100%"></rect>
                  </svg>
               </div>
               <div class="canvas_img-wrapper">
                  <img class="canvas_img" src={brian} alt=""/>
               </div>
               <div class="canvas_copy canvas_copy--left">
                  <span class="canvas_copy_subtitle">Co-Founder</span>
                  <strong class="canvas_copy_title">Brian</strong>
                  <strong class="canvas_copy_title">Paul</strong>
                  <span class="canvas_copy_details">Full-Stack</span>
               </div>
            </a>
            <a  class="canvas">
               <div class="canvas_border">
               <svg>
                     <defs>
                        <linearGradient id="grad-orange" x1="0%" y1="0%" x2="100%" y2="0%">
                           <stop offset="0%" stopColor="#fd8944" stopOpacity="1" ></stop>
                           <stop offset="100%" stopColor="#994b17" stopOpacity="1"></stop>
                        </linearGradient>
                        <linearGradient id="grad-red" x1="0%" y1="0%" x2="100%" y2="0%">
                           <stop offset="0%" stop-color="#e92c3e"></stop>
                           <stop offset="100%" stop-color="#e92c3e"></stop>
                        </linearGradient>
                     </defs>
                     <rect id="rect-grad" class="rect-gradient" fill="none" stroke="url(#grad-orange)" stroke-linecap="square" stroke-width="12" stroke-miterlimit="30" width="100%" height="100%"></rect>
                  </svg>
               </div>
               <div class="canvas_img-wrapper">
                  <img class="canvas_img" src={david} alt=""/>
               </div>
               <div class="canvas_copy">
                  <span class="canvas_copy_subtitle">Co-Founder</span>
                  <strong class="canvas_copy_title">David</strong>
                  <strong class="canvas_copy_title">Nunez</strong>
                  <span class="canvas_copy_details">Full-Stack</span>
               </div>
            </a>
         </div>
         </div>
        
        );
    }
}

export default TeamCard;