import {React,useRef,useEffect,useState} from 'react';
import './CoreValueCard.css';
import { Tween } from 'gsap/gsap-core';
import {TweenMax,Power2,TimelineMax} from "gsap";





function CoreValueCard(){

    
    return(
<div>
<div class="CoreValCards-row">
<div  class="CoreValCard first-card">
	<div class="CoreValCard-image" />
	<div class="CoreValCard-title">
		Creativity.
	</div>
	<div class="CoreValCard-desc">
		We strive to bring something new to the table for every client. We aim to set trends. We aim to be LIT.
	</div>
</div>

<div class="CoreValCard">
	<div class="CoreValCard-image" />
	<div class="CoreValCard-title">
		Ambition.
	</div>
	<div class="CoreValCard-desc">
		At LIT. we aim for the stars. We don't believe anything is too high for anyone, anyone can accomplish anything with hardwork, consistency and faith.
	</div>

</div>
<div class="CoreValCard">
	<div  class="CoreValCard-image" />
	<div class="CoreValCard-title">
		Growth.
	</div>
	<div class="CoreValCard-desc">
		We try to push the limits of our abilities and aim to learn from our mistakes.
		<span>"In every adversity lies the seed of an equal or greater opportunity." - N.Hill</span>
	</div>
	
</div>
</div>
</div> 
  );
}

export default CoreValueCard;