import React from 'react';
import './Perspective.css';

function Perspective(){
return (
    <div class="contain">
    <div class="perspective-text">
      <div class="perspective-line">
        <p></p>
        <p>Toronto</p>
      </div>
      <div class="perspective-line">
        <p>Toronto</p>
        <p>Based</p>
      </div>
      <div class="perspective-line">
        <p>Web</p>
        <p>Digital</p>
      </div>
      <div class="perspective-line">
        <p>Design</p>
        <p>Marketing</p>
      </div>
  
      </div>
    </div>
);
    
}

export default Perspective;