import React, { useRef, useState } from 'react';
import './PricingTable.css';
import { TweenMax, Power2 } from "gsap";
import FadeIn from 'react-fade-in'

function PricingTable(){
    const tableRef = useRef(null);
    useState(()=>{
        TweenMax.fromTo(tableRef.current, 4, {z:"-100%", ease:Power2.easeInOut}, {z:"0%"})
    });
return(
    <FadeIn transitionDuration="400">
        <div class="pricing">
    <div ref={tableRef}>
    <div class="pricing-container group">
        <div class="grid-1-5">
            <h2>Gold</h2>
            <h3><sup>$</sup>500 - <sup>$</sup>1,000</h3>
            <p>Single Page</p>
            <ul>
                <li>Custom Domain Included</li>
                <li>No Animations</li>
                <li>Responsive</li>
        <li>Social Media Integration</li>
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>
    
        <div class="grid-1-5">
            <h2>PLatinum</h2>
            <h3><sup>$</sup>1,000-<sup>$</sup>4,000</h3>
            <p>2-5 Pages</p>
            <ul>
        <li>Custom Domain</li>
                <li>1-3 Animations</li>
                <li>Mobile Responsiveness</li>
                <li>Social Media Integration</li>
        <li>1 Revision</li>
            </ul>    
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>        
        </div>
        <div class="grid-1-5">
            <h2>Diamond</h2>
        <h3><sup>$</sup>5,000-<sup>$</sup>10,000</h3>
            <p>5-8 Pages</p>
            <ul>
        <li>Custom Domain</li>
                <li>3-5 Animations</li>
                <li>Mobile Responsiveness</li>
                <li>Custom Icons</li>
        <li>404 Redirect</li>
                <li>Social Media Integration</li>
        <li>3 Revisions</li>
            </ul>    
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>        
        </div>
        <div class="grid-1-5">
            <h2>Master</h2>
            <h3><sup>$</sup>10,000+</h3>
            <p>Let's Talk</p>
            <ul>
        <li>All the perks of Diamond</li>
                <li>E-commerce Functionality</li>
                <li>Database Integration</li>
        <li>10+ Animations</li>
                <li>Customized Plan</li>
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>        
    </div>
    </div>
    </div>
    </FadeIn>
);

}

export default PricingTable;