import React, {Component, useState,useEffect,useRef } from 'react'
import './Contact.css';
import exit from "../../assets/error.svg";
import { propTypes } from 'react-bootstrap/esm/Image';
import { Tween } from 'gsap/gsap-core';
import {TweenMax,Power2,TimelineMax} from "gsap";
import { render } from '@testing-library/react';

class Contact extends Component{
 /* 
  render(){
    function Load(){
      TweenMax.to(contactRef.current,1,{opacity:0});
      TweenMax.to(contactRef.current, 1, {x:-1000*1.2, y:0, transformOrigin:"0% 100%"});
    }

    function contactClickedAnimation(){
      TweenMax.to(contactRef.current, 1, {opacity:1,x:this.props.width/3.5, y:this.props.height/2,});
    }

 


  const ContactClicked = () =>{
      if(!this.props.clicked){
          
          contactClickedAnimation()
          }
          else{
              Load();
              
          }
  }
    return(
        <div class="container">
  <div class="kard">
    <div class="front face">
      <h1 className="front-title">LIT.</h1>
    </div>
    <div class="back face">
    <img onClick={ContactClicked} width="50%" height="50%" src={exit} className="exit "></img>
      <h2 clasName="back-title">Think LIT.</h2>
      <ul className="Item-list">
      <li className="item">
          <span className="info-title">Get In Touch!</span>
        </li>
        <li className="item">
        <div id="examples">
    <div class="example">
      <a class="hover hover-1">647-702-7168</a>
    </div>
    </div>
        </li>
        <li className="item">
        <div id="examples">
    <div class="example">
          <a className="info hover hover-1" href="mailto:davidjnunezf@gmail.com">
            davidjnunezf@gmail.com
          </a>
          </div>
          </div>
    
        </li>
        <li className="item">
          <span className="info-title"></span>
          <a href="https://www.instagram.com/thinklit/" className="icon">
          <i class="fa fa-instagram"></i>
          </a>
          <a className="icon"href="https://www.facebook.com/ThinkLit2021">
          <i class="fa fa-facebook"></i>
          </a>
          <a className="icon" href="www.thinklit.ca">
          <i class="fa fa-twitter"></i>
          </a>
        </li>
      </ul>
      <a>
        <div class="icon-share"></div>
      </a>
    </div>
  </div>
</div>

    );
  }*/
}

export default Contact;