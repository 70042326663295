
import React,{useRef, useEffect, Component} from 'react';
import Row from './Components/LandingPage/Row';
import Column from './Components/LandingPage/Column';
import './App.css';
import {HashRouter as Router} from 'react-router-dom'
import Route from 'react-router-dom/Route'
import Navbar from './navbar/Navbar'
import LandingPage from './Components/LandingPage/LandingPage'
import Portfolio from './Components/Portfolio/Portfolio';
import Contact from './Components/Contact/Contact';
import Team from './Components/Team/Team';
import Pricing from './Components/Pricing/Pricing';



function App(){
    
    return(
      <div>
        

        <Router>   

            <Route path="/" exact strict component = {LandingPage}/>
            <Route path="/home" exact strict component = {LandingPage}/>
            <Route path="/portfolio" exact strict component = {Portfolio}/>
            <Route path="/pricing" exact strict component = {Pricing}/>
            <Route path="/team" exact strict component = {Team}/>
        </Router> 
      </div>
    );
}

export default App;
