import {React,useRef,useState} from 'react'
import Navbar from '../../navbar/Navbar';
import TeamCard from './TeamCard';
import './Team.css'
import FadeIn from 'react-fade-in';
import { Tween } from 'gsap/gsap-core';
import {TweenMax,Power2,TimelineMax} from "gsap";
import CoreValueCard from './CoreValueCard.js';
import {useIntersection} from 'react-use';

var textRef;
var teamRef;
var word1Ref;
var word2Ref;
var coreRef;
var width = window.innerWidth;
var height = window.innerHeight;
var mobile = false;
function Titlemove(){
    TweenMax.to(textRef.current,2,{x: '-100%',ease: Power2.easeInOut,delay:'1'})
}
function TeamMove(){
    TweenMax.to(teamRef.current,2,{x: '100%',opacity:1,ease: Power2.easeInOut})
}
function TeamBack(){
    TweenMax.to(teamRef.current,2,{x: '0%',opacity:1,ease: Power2.easeInOut,delay:'1'})
}
function TitleBack(){
    TweenMax.to(textRef.current,2,{x:'0%',ease: Power2.easeInOut,delay:'1'},)
}
function CoreChange(){
    TweenMax.to(word1Ref.current,2,{x:'-30%',ease: Power2.easeInOut,delay:'1', fontSize:"10vw"},);
    TweenMax.to(word2Ref.current,2,{x:'28%',ease: Power2.easeInOut,delay:'1',fontSize:"10vw"},);
    TweenMax.to(coreRef.current,2,{y:"-100%",ease:Power2.easeInOut,delay:'1'});
    TweenMax.to(word2Ref.current,2,{y:'80%',ease: Power2.easeInOut,delay:'1'},);
}

function CoreRevert(){
    TweenMax.to(word1Ref.current,1,{x:'0',ease: Power2.easeInOut,delay:'0.5'},);
    TweenMax.to(word2Ref.current,1,{x:'0',ease: Power2.easeInOut,delay:'0.5'},);
    TweenMax.to(coreRef.current,1,{y:"0",ease:Power2.easeInOut,delay:'0.5'});
    TweenMax.to(word2Ref.current,1,{y:'0',ease: Power2.easeInOut,delay:'0.5'},);
}

function Team(){
    textRef= useRef(null);
    teamRef= useRef(null);
    word1Ref = useRef(null);
    word2Ref = useRef(null);
    coreRef = useRef (null);
    const sectionRef= useRef(null);
    const sectionRef2= useRef(null);
    const topSectionAnimation = useIntersection(sectionRef,{
        root:null,
        rootMargin:"0px",
        threshold: 0.5,
    });
    const fadeIn = () =>{
        TweenMax.to(sectionRef2.current,1, {
            opacity:1,
            y: -60,
            ease:'power4.out',
            stagger: {
                amount: .8
            }
        });
    }
    const fadeOut = () =>{
        TweenMax.to(sectionRef2.current,1, {
            opacity:0,
            y: -20,
            ease:'power4.out',
          
    });
    }

    function big(){
        CoreRevert();fadeIn() ;
    }

    function small(){
        CoreChange();fadeOut() ;
    }
    function isMobile(){
        if(width>=height){
            return false;
        }
        else return true;
    }
    if(!isMobile()){
    topSectionAnimation && topSectionAnimation.intersectionRatio < 0.5
       ? big():small();
    }
    return(
        <div className="port">
            <div className="stick">
            <Navbar></Navbar>
            </div>
            <FadeIn delay="700" >
           <div className="fadeIn" ref={sectionRef2}>
            <div ref={textRef} className="intro-text fadeIn">
            Who Are We?
            </div>
          <div ref={teamRef} className="fadeIn">
            <TeamCard className="fadeIn" ></TeamCard>
            </div>
            </div>
        
            <div className="core-section" ref={sectionRef}>
            <div className="intro-text"><div ref={word1Ref}>Core</div><div ref={word2Ref}>Values</div></div>
            <div class="core-sec" ref={coreRef}><CoreValueCard></CoreValueCard></div>
            </div>
            </FadeIn>
        </div>
    );
}

export default Team;


// <div>Entrepeneurs,Programmers,Artists</div>
//<div>What are we?</div>
//<div>Passionate,Ambitious,Hungry,Bold,Creative</div>