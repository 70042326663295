import React, { useRef, useEffect, useState } from "react";
import IsometricGrid, { Cell } from "react-isometric-grid";
import dynamics from "dynamics.js";
import { Tween } from "gsap/gsap-core";
import { TweenMax, Power2, TimelineMax } from "gsap";
import Navbar from "../../navbar/Navbar";
import "./Portfolio.css";

import dicks from "../../assets/dicks.jpg";
import litkit from "../../assets/litkit.png";
import kpc from "../../assets/kpc.png";
import gym from "../../assets/gym.jpg";
import you from "../../assets/you.jpg";

const topIsoLayer = { height: "20vw", width: "20vw", borderRadius: "10px" };
var isoGridStyle = { height: "30vh", width: "60vw", paddingTop:"5vh",};
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {  
  console.log("raaaan");
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const[iso_Spacing, setSpacing] = useState({margin:"1vw"});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth);
      
      if(window.innerWidth < 1200){
        console.log("executed");
        isoGridStyle = {height: "30vh", width: "80vw", paddingTop:"5vh",}
        
      }

    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
let linkMap = new Map();
linkMap.set('litkit','https://litkit.ca/');
linkMap.set('kpc','https://kpcycling.shop/');
linkMap.set('gym','');
linkMap.set('you','');


let imgMap = new Map();
imgMap.set('litkit', litkit);
imgMap.set('kpc', kpc);
imgMap.set('gym', gym);
imgMap.set('you', you);


const imgs = [litkit, kpc, gym, you];


//const [count, setCount] = useState(0);

function getCard(name, text, st) {
  if(st == 0){
    return (
      <div class="card1">
        
        <img className="image cell-size" src={imgMap.get(name)} />
        <div className="overlay">
        <div className="cont ">
        <a target="_blank" href={linkMap.get(name)} className="bton">{text}</a>
        
        </div>
        </div>
       
      </div>
    );
  }
  else{
    return (
      <div class="card1">
        
        <img className="image cell-size" src={imgMap.get(name)} />
        <div className="overlay">
        <div className="cont ">
        <a   className="bton2">{text}</a>
        
        </div>
        </div>
       
      </div>
    );
  }
  }
  

function getbottomLayer(){
  return(
    <div className="cell-size"></div>
  );
}

function Portfolio() {
  const isoRef = useRef(null);
  const cell1Ref = useRef(null);
  const textRef = useRef(null);
  const tempRef = useRef(null);
  //const {height, width} = useWindowDimensions(isoSpacing);

 // const[isoSpacing, setSpacing] = useState({margin:"1vw"});

 const mediaMatch = window.matchMedia('(max-width: 1000px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
const styles = {
  isoGrid: rightSize => ({
    height: "30vh",
    width: rightSize? "100vw" : "60vw",
    top:"1vw"
  }),
  isoSpacing: rightSize => ({
    margin: rightSize? "15px" : "5px"
  })
};
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    
    TweenMax.fromTo(  
      isoRef.current,
      2,
      { opacity: 0, ease: Power2.easeInOut },
      { opacity: 0, delay: "1.2" }
    );
    TweenMax.fromTo(
      isoRef.current,
      2,
        { opacity: 0, x: "-100%", ease: Power2.easeInOut },
      { opacity: 1, x: "0%", delay: "1.2" }
    );
    TweenMax.fromTo(
      tempRef.current,
      2,
        { opacity: 0, x: "100%", ease: Power2.easeInOut },
      { opacity: 1, x: "0%", delay: "1.2" }
    );
    TweenMax.fromTo(
      cell1Ref.current,
      2,
      { opacity: 0, x: "-100%", ease: Power2.easeInOut },
      { opacity: 1, x: "0%", delay: "3.2", }
    );
    TweenMax.fromTo(
      textRef.current,
      2,
      { opacity: 0, x: "100%", ease: Power2.easeInOut },
      { opacity: 1, x: "0%", display: "flex", delay: "1.2" }
    );
    return mediaMatch.removeListener(handler);
  });
  return (
    <div className="portfolio">
      <div className="stick change">
        <Navbar />
      </div>
      <div className="contents">
        <div ref={isoRef} className="iso" >
          <IsometricGrid
            shadow
            transform=" rotateZ(30deg) rotateX(40deg) rotateY(-30deg)"
            stackItemsAnimation={{
              properties: function (pos) {
                return {
                  translateZ: (pos + 1) * 30,
                  rotateZ: getRandomInt(-4, 4),
                };
              },
              options: function (pos, itemstotal) {
                return {
                  type: dynamics.bezier,
                  duration: 500,
                  points: [
                    { x: 0, y: 0, cp: [{ x: 0.2, y: 1 }] },
                    { x: 1, y: 1, cp: [{ x: 0.3, y: 1 }] },
                  ],
                  delay: (itemstotal - pos - 1) * 40,
                };
              },
            }}
            //style={isoGridStyle}
            style={styles.isoGrid(matches)}
          >
    
         
           <Cell
        

        style={styles.isoSpacing(matches)}
        comp={getCard.bind(this, 'litkit', 'Visit', 0)}
        bottomLayer={getbottomLayer}
        //layerStyle={{width:"50vw", height:"20vh"}}
        layers={[
          "https://picsum.photos/600/600/?random",
          "#9972fc",
          "#c322a3",
          "#9eb5c2",
        ]}
      />
         
    
            <Cell
            bottomLayer={getbottomLayer}
            //className="iso-spacing"
              style={styles.isoSpacing(matches)}
              //layerStyle={{width:"10vw", height:"10vh"}}
              comp={getCard.bind(this, 'kpc', 'Visit', 0)}
              layers={[
                "https://picsum.photos/200/300/?random",
                "#9972fc",
                "#c322a3",
                "#9eb5c2",
              ]}
            />
            <Cell
            bottomLayer={getbottomLayer}
              style={styles.isoSpacing(matches)}
              //layerStyle={{width:"10vw", height:"10vh"}}
              comp={getCard.bind(this, 'gym', 'Coming Soon', 1)}
              layers={[
                "https://picsum.photos/400/300/?random",
                "#9972fc",
                "#c322a3",
                "#9eb5c2",
              ]}
            />
            <Cell
            bottomLayer={getbottomLayer}
              style={styles.isoSpacing(matches)}
              //layerStyle={{width:"10vw", height:"10vh"}}
              comp={getCard.bind(this, 'you', 'Your Project', 1)}
              layers={[
                "https://picsum.photos/200/500/?random",
                "#9972fc",
                "#c322a3",
                "#9eb5c2",
              ]}
            />
          </IsometricGrid>
        </div>
              
          <div ref={tempRef} transform=" rotateZ(0deg) rotateX(30deg) rotateY(30deg)" class="temp">
    
    <div  class="all-text">
    
<div class="title">
    <span class="block"></span>
    <div class="title-x"><p>Our Projects</p><span></span></div>
</div>
<div class="role">
    <div class="block"></div>
    <div class="description"><p>We love what we do and so will you</p><span></span></div>
</div>
    </div>
</div>
        </div>

        
      </div>
    
  );
}

export default Portfolio;
