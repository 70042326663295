import React, { useRef, useState } from 'react';
import './PricingTable.css';
import { TweenMax, Power2 } from "gsap";
import FadeIn from 'react-fade-in'

function PricingTable2(){
    const tableRef = useRef(null);
    useState(()=>{
        TweenMax.fromTo(tableRef.current, 4, {z:"-100%", ease:Power2.easeInOut}, {z:"0%"})
    });
return(
    <FadeIn transitionDuration="400">
        <div class="pricing">
    <div ref={tableRef}>
    <div class="pricing-container group">
        <div class="grid-1-5">
            <h2>Gold</h2>
            <h3><sup>$</sup>1,000/mo</h3>
            <p>1 Platforms</p>
            <ul>
                <li>Custom Design Ads</li>
                <li>30 ads a month</li>
                <li>Comment Monitoring</li>
                <li>3 boosted posts</li> 
            
               
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>
    
        <div class="grid-1-5">
            <h2>Platinum</h2>
            <h3><sup>$</sup>2,000/mo</h3>
            <p>2 Platforms</p>
            <ul>
            <li>Custom Design Ads</li>
            <li>30 ads a month</li>
            <li>Email Marketing</li>
            <li>Comment Monitoring</li>
            <li>6 boosted posts</li>
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>
        <div class="grid-1-5">
            <h2>Diamond</h2>
            <h3><sup>$</sup>3,000/mo</h3>
            <p>3 Platforms</p>
            <ul>
            <li>Custom Design Ads</li>
                <li>45 ads a month</li>
                <li>Email Marketing</li>
                <li>Comment Monitoring</li>
                <li>9 boosted posts</li>
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>
        <div class="grid-1-5">
            <h2>Master</h2>
            <h3><sup>$</sup>4,000/mo</h3>
            <p>4 Platforms</p>
            <ul>
            <li>Custom Design Ads</li>
                <li>45 ads a month</li>
                <li>Email Marketing</li>
                <li>Comment Monitoring</li>
                <li>12 boosted posts</li>
                <li>SEO</li>
                
            </ul>
            <a href="mailto:thinklit2k21@gmail.com" class="button">Let's Talk</a>
        </div>    
    </div>
    </div>
    </div>
    </FadeIn>
);

}

export default PricingTable2;