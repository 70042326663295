import React from 'react'
import './Card.css'

function Card(){
    return(
        <div style={{width:"30vw"}} className="temp">
            <main class="page-content">
    <div class="card">
        <div class="content">
            <h2 class="title">Mountain View</h2>
            <p class="copy">Check out all of these gorgeous mountain trips with beautiful views of, you guessed it, the mountains</p><button class="btn">View Trips</button></div>
    </div>
   
</main>
        </div>
    );
}

export default Card;

/* <div class="card">
        <div class="content">
            <h2 class="title">To The Beach</h2>
            <p class="copy">Plan your next beach trip with these fabulous destinations</p><button class="btn">View Trips</button></div>
    </div>
    <div class="card">
        <div class="content">
            <h2 class="title">Desert Destinations</h2>
            <p class="copy">It's the desert you've always dreamed of</p><button class="btn">Book Now</button></div>
    </div>
    <div class="card">
        <div class="content">
            <h2 class="title">Explore The Galaxy</h2>
            <p class="copy">Seriously, straight up, just blast off into outer space today</p><button class="btn">Book Now</button></div>
    </div>*/