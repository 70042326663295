import React,{useRef, useEffect} from 'react';
import './column.css'

function Column(){
    return(
<div className="column">
    
</div>
    );
}

export default Column;